var app = {
    siteUrl: "",
    baseUrl: "",
    debug: false,
    user: {
        id: 0
    },
    default_url: undefined,
    geonameUrl: undefined,
    pageError: undefined,
    page404: undefined,
    page403: undefined,
    app_route_base: '',
    date_format: 'd/m/Y',
    suppressWarning: false,
    websocket: '',
    websocket_clientid: '',
    websocket_client: null,
    websocket_ws: null,
    lang: 'it',
    init: function (default_url) {
        app.default_url = default_url || 'dashboard';

        errorHandler.init();

        // valutare se rimuovere visto che c'è già sul complete ajax ace
        app.runBind();

        // Laravel CSRF protection
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        if (app.websocket != '') {
            app.websocket_ws = new WebSocket('wss://websocket.weconstudio.it/ws');

            app.websocket_client = Stomp.over(app.websocket_ws);

            var on_connect = function () {
                console.log("Connected to websocket");

                if(app.websocket_clientid=='') {
                    app.websocket_clientid = window.localStorage.getItem('websocket_clientid');
                    if (typeof window.localStorage != 'undefined') {
                        if (!app.websocket_clientid) {
                            app.websocket_clientid = app.getUniqueID();
                            window.localStorage.setItem('websocket_clientid', app.websocket_clientid);
                        }
                    }
                }

                app.websocket_client.subscribe("/queue/" + app.websocket + "_" + app.websocket_clientid, function (d) {

                    app.websocket_managementmessage(d);

                    app.websocket_onmessage(d);
                });
            };
            var on_error = function (a) {
                app.websocket_onerror(a);
            };

            app.websocket_client.connect('ws', '12345678', on_connect, on_error, 'websocket');
            app.websocket_client.debug = false;
        }

        if (typeof $('#page-content-section').ace_ajax != 'undefined') {
            // ACE Ajax manager
            $('#page-content-section').ace_ajax({
                content_url: function (hash) {
                    // se l'url contiene un cancelletto ritorno senza procedere alla vera chiamata
                    if (hash.indexOf("#") >= 0)
                        return;

                    // hash is the value from document url hash
                    // take "url" param and return the relevant url to load
                    var to = app.app_route_base;
                    if (to != '' && to != '/')
                        to += "/";
                    return to + hash;
                },
                default_url: app.default_url,
                loading_icon: "fa fa-cog fa-2x blue fa-spin"
            }).on('ajaxloadcomplete', function (e, params) {
                app.runBind();
            });
        }
    },

    getUniqueID: function (name) {
        return Math.random().toString(36).substr(2, 9)
    },

    websocket_onmessage: function (data) {
        console.info("To read from websocket override function: 'app.websocket_onmessage'", data);
    },
    websocket_onerror: function (data) {
        console.info("To read from websocket override function: 'app.websocket_onerror'", data);
    },

    /**
     *
     * Serve per effettuare operazioni di manutenzione sui client, oppure avvisare l'utente di un eventuale blocco del servizio.
     * Gli utenti collegati alla coda riceveranno il messaggio bloccante o meno
     *
     * Messaggio di esempio:
     *
        {
            "weconstudio_management":{
              "message":{
                "title":"Aggiornamento di sistema",
                "message":"Attenzione: il sistema sta per essere aggiornato agli adeguamenti per la fatturazione elettronica. Ci scusiamo per eventuali disagi.",
                "type":"confirm" // per rendere messaggio bloccante
              },
              "command":"reload"
            }
        }
     *
     * @param data
     */
    websocket_managementmessage: function(data){

        var data_received = JSON.parse(data.body);

        if(typeof data_received != 'undefined'){
            if(typeof data_received.weconstudio_management != 'undefined'){
                var command = data_received.weconstudio_management;
                if(typeof command.message != 'undefined'){
                    if(command.message.type=='confirm'){
                        confirm(command.message.message);
                    }
                    app.error(command.message.title, command.message.message);
                }

                if(typeof command.command != 'undefined'){
                    switch(command.command){
                        case 'reload':
                            app.reload();
                            location.reload();
                            break;
                    }
                }
            }
        }
    },

    logout: function () {
        $.post(app.siteUrl + "/logout")
            .done(function () {
                app.href("/", 0);
            });
    },

    /**
     * Aggiunge N scripts alla pagina
     *  es: getMultiScripts(aScripts).done(function(){...});
     * @param arr
     * @param path
     * @returns {*}
     */
    getMultiScripts: function (arr, path) {
        var _arr = jQuery.map(arr, function (scr) {
            return jQuery.getScript((path || "") + scr);
        });

        _arr.push(jQuery.Deferred(function (deferred) {
            jQuery(deferred.resolve);
        }));

        return jQuery.when.apply(jQuery, _arr);
    },

    setLocale: function (locale) {
        app.href(app.siteUrl + "/locale?locale=" + locale, 0);
        // $.post(app.siteUrl + "/locale", {locale: locale})
        //     .done(function (data) {
        //         window.location.href = window.location.href.replace("#", "/");
        //     })
        //     .fail(function (data) {
        //         console.error(app.parseAjaxError(data));
        //     });
    },

    image_error: function (imgObj) {
        imgObj.src = 'images/image_missing.png';
    },

    escapeEntityMap: {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
    },
    escapeHtmlText: function (string) {
        return String(string).replace(/[&<>"'`=\/]/g, function (s) {
            return app.escapeEntityMap[s];
        });
    },
    /**
     *
     * @param nome
     * @param cognome
     * @param comune
     * @param provincia
     * @param dataNascita
     * @param sesso: M|F
     * @param callback
     */
    calcoloCodiceFiscale: function (nome, cognome, comune, provincia, dataNascita, sesso, stato, callback) {

        if (nome == "") {
            app.warning("", "Campo vuoto 'nome'");
            return;
        }
        if (cognome == "") {
            app.warning("", "Campo vuoto 'cognome'");
            return;
        }

        if (comune == "" && provincia != "ES") {
            app.warning("", "Campo vuoto 'comune'");
            return;
        }
        if (provincia == "") {
            app.warning("", "Campo vuoto 'provincia'");
            return;
        }
        if (dataNascita == "") {
            app.warning("", "Campo vuoto 'dataNascita'");
            return;
        }
        if (sesso == "") {
            app.warning("", "Campo vuoto 'sesso'");
            return;
        }

        stato = stato || "";

        $.post("?p=server&a=calcolaCodiceFiscale", {
            nome: nome,
            cognome: cognome,
            data: dataNascita,
            comune: comune,
            provincia: provincia,
            sesso: sesso,
            stato: stato
        }, function (data) {
            if (data.response) {
                if (typeof callback == 'function') {
                    callback(data.message);
                } else {
                    app.warning("", "Callback mancante");
                    console.log(data.message);
                }
            }

        }).error(function () {
            app.error('Errore durante la chiamata calcolaCodiceFiscale');
        });
    },

    initAutocomplete: function () {

        $('[data-autocomplete]').each(function () {
            var tipo = $(this).attr('data-autocomplete');

            var url = $(this).attr('data-url');
            url = url || "geoname";

            if ($.trim($(this).html()) === "") { // così posso partire con una option già selezionata
                $(this).html("<option></option>");
            }

            $(this).ajaxChosen({
                type: 'GET',
                url: app.baseUrl + "/" + url + "/" + tipo,
                dataType: 'json'
            }, function (data) {
                var results = [];

                $.each(data, function (i, val) {
                    results.push({value: val.value, text: val.text});
                });

                return results;
            }, {allow_single_deselect: true});
        });

        $('[data-autocompleteui]').each(function () {

            var tipo = $(this).attr('data-autocomplete');

            var itemOptional = $(this).attr('data-itemopzionale');
            itemOptional = itemOptional || 0;

            var url = $(this).attr('data-url');
            url = url || "geoname";

            var callback = $(this).attr('data-callback');

            function getTextValueFromResponseAutocomplete(obj) {

                if (typeof obj.txt != 'undefined')
                    return obj.txt;

                return "";
            }

            $(this).autocomplete({
                source: function (request, response) {
                    $.getJSON(url + "/" + tipo + "/" + request.term, function (data) {
                        response($.map(data.message, function (value, key) {

                            var textValue = "";
                            if (typeof value == 'string') {
                                textValue = value;
                            } else {
                                if (typeof value == 'object') {
                                    textValue = getTextValueFromResponseAutocomplete(value);
                                }
                            }

                            return {
                                label: textValue,
                                obj: value,
                                value: key
                            };
                        }));
                    });
                },
                minLength: 0,
                select: function (event, ui) {
                    var textValue = "";

                    if (typeof ui.item.obj == 'object') {
                        textValue = getTextValueFromResponseAutocomplete(ui.item.obj);
                    } else {
                        textValue = ui.item.label;
                    }

                    $(this).val(textValue);

                    $(this).attr('data-id', ui.item.value);

                    if (callback) {
                        functionCallBack = eval(callback);
                        if (typeof functionCallBack == 'function') {
                            functionCallBack($(this), tipo, ui.item);
                        } else {
                            console.warn("Callback non trovata: " + callback);
                        }
                    }

                    return false;
                },
                change: function (event, ui) {
                    if (ui.item) {
                        // valido
                        $(this).val(ui.item.label);
                        $(this).attr('data-id', ui.item.value);
                    } else {
                        if (!itemOptional) {
                            $(this).attr('data-id', 0);
                            $(this).val('');
                        }
                    }
                },
            });
        });
    },

    parseAjaxError: function (data) {
        var error = "";
        if (typeof data.responseJSON != 'undefined') {
            var json = data.responseJSON;
            if (typeof json.errors !== 'undefined') {
                json = json.errors;
            }

            if (typeof json.message !== 'undefined')
                return json.message;

            $.each(Object.keys(json), function () {
                if (typeof json[this] !== 'undefined' && json[this].length > 0) {
                    $.each(json[this], function () {
                        error += this + "<br>";
                    });
                }
            });
        }

        if ($.trim(error) == "")
            error = T.serverError;

        return error;
    },

    loginAs: function (node) {
        var from = $(node).attr('data-from');
        var to = $(node).attr('data-to');
        var id = $(node).attr('data-id');

        app.block(1);
        $.post(app.baseUrl + "/user/login_as", {id_user: id, to: to, from: from})
            .done(function (data) {
                if (data.response) {
                    if ($.trim(data.message) === "")
                        app.href(app.baseUrl, 0);
                    else
                        app.href(app.baseUrl + "/" + data.message, 0);
                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function (data) {
                app.block(0);
                app.error("", app.parseAjaxError(data));
            });
    },

    escapeHtml: function (html) {
        var txt = document.createElement("textarea");
        txt.textContent = html;
        return txt.innerHTML;
    },

    runBind: function () {
        // geonames
        geonames.init();

        // login_as
        $('[data-interaction=login_as]').unbind('click').bind('click', function () {
            app.loginAs(this);
        });

        // fix per forzare l'input sulle select2 nei modal
        $.fn.modal.Constructor.prototype.enforceFocus = function () {
        };

        // inizializzazione timepicker in italiano
        if (jQuery.datetimepicker) {
            jQuery.datetimepicker.setLocale(app.lang);
        }

        $(".euro").inputmask('decimal', {digits: 2});

        /* inizializzazione editor descrizione */
        $('.wysiwyg-editor').each(function () {
            var editor = $(this).ace_wysiwyg({
                toolbar: [
                    'font',
                    null,
                    'fontSize',
                    null,
                    {name: 'bold', className: 'btn-info'},
                    {name: 'italic', className: 'btn-info'},
                    {name: 'strikethrough', className: 'btn-info'},
                    {name: 'underline', className: 'btn-info'},
                    null,
                    {name: 'insertunorderedlist', className: 'btn-success'},
                    {name: 'insertorderedlist', className: 'btn-success'},
                    {name: 'outdent', className: 'btn-purple'},
                    {name: 'indent', className: 'btn-purple'},
                    null,
                    {name: 'justifyleft', className: 'btn-primary'},
                    {name: 'justifycenter', className: 'btn-primary'},
                    {name: 'justifyright', className: 'btn-primary'},
                    {name: 'justifyfull', className: 'btn-inverse'},
                    null,
                    {name: 'createLink', className: 'btn-pink'},
                    {name: 'unlink', className: 'btn-pink'},
                    null,
                    null,
                    null,
                    'foreColor',
                    null,
                    {name: 'undo', className: 'btn-grey'},
                    {name: 'redo', className: 'btn-grey'}
                ],
                'wysiwyg': {
                    /* fileUploadError: showErrorAlert */
                }
            }).prev().addClass('wysiwyg-style2');

            if ($(this).hasClass("wysiwyg-editor-disabled"))
                $(this).attr("contenteditable", "false");
        });

        $("[data-facursor]").each(function () {
            $(this).awesomeCursor($(this).attr('data-facursor'), {color: $(this).attr("data-facursor-color")});
        });

        $("[data-interaction=checkenabler]").each(function () {
            if (!$(this).attr("data-checkEnablerSet")) {
                $(this).attr("data-checkEnablerSet", "1");

                function checkEnablerFnz(obj) {
                    var objRef = $("." + obj.attr("data-refclass"));
                    if (obj.is(":checked"))
                        objRef.removeAttr('disabled');
                    else
                        objRef.attr("disabled", "disabled");
                }

                $(this).bind("click", function () {
                    checkEnablerFnz($(this));
                });
            }
            ;
            checkEnablerFnz($(this));
        });

        $(".datepicker").datetimepicker({
            dayOfWeekStart: 1,
            format: app.date_format,
            closeOnDateSelect: true,
            timepicker: false,
            scrollMonth: false,
            scrollInput: false,
        });

        $(".datetimepicker").datetimepicker({
            dayOfWeekStart: 1,
            step: 30,
            format: app.date_format + " H:i",
            closeOnTimeSelect: true,
            scrollMonth: false,
            scrollInput: false,
        });

        $(".timepicker").datetimepicker({
            format: "H:i",
            datepicker: false,
            step: 30,
            closeOnTimeSelect: true,
            scrollMonth: false,
            scrollInput: false,
        });

        $("[data-interaction=toggleEditDiv]").unbind("change").bind("change", app.runToggleEditDiv);
        app.runToggleEditDiv();

        // inizializzazione dei select
        $('select:not(.select2-hidden-accessible):not(.select2):not(.no-select2):not(.autocomplete):not([data-geoname]):not([data-dualbox])').select2({
            minimumResultsForSearch: 8
        });

        // template di visualizzazione dei risultati della select2
        function autocomplete_templateResult(data) {
            return data.text;
        }

        // template di visualizzazione del risultato selezionato della select2
        function autocomplete_templateSelection(data) {
            if (typeof data.label == "undefined")
                data.label = data.text;
            return data.label;
        }

        $('select.autocomplete:not(.select2-hidden-accessible):not(.select2):not(.phpdebugbar-datasets-switcher):not([data-dualbox])').each(function () {
            var $url = $(this).attr("data-url");
            var allow_clear = $(this).attr("data-allow-clear") || false;

            if (allow_clear && !$(this).attr("data-placeholder"))
                $(this).attr("data-placeholder", '');

            var callback_get_params = $(this).attr("data-callback_get_params") || '';
            var minimumInputLength = ((typeof $(this).attr("data-length") != 'undefined') ? $(this).attr("data-length") : 1);
            $(this).select2({
                ajax: {
                    url: $url,
                    dataType: 'json',
                    delay: 100,
                    type: 'POST',
                    data: function (params) {
                        var search = params.term;
                        var fnz = app.eval(callback_get_params);
                        if (typeof fnz == 'function') {
                            var _params = fnz(params);
                            search = {
                                search: search,
                                params: _params
                            };
                        }
                        return {
                            search: search, // search term
                            page: params.page
                        };
                    },
                    processResults: function (data, params) {
                        // parse the results into the format expected by Select2
                        // since we are using custom formatting functions we do not need to
                        // alter the remote JSON data, except to indicate that infinite
                        // scrolling can be used
                        params.page = params.page || 1;

                        return {
                            results: data.items
                        };
                    },
                    cache: true
                },
                escapeMarkup: function (markup) {
                    return markup;
                },
                minimumInputLength: minimumInputLength,
                cache: true,
                allowClear: allow_clear,
                templateResult: autocomplete_templateResult,
                templateSelection: autocomplete_templateSelection
            }).on("select2:unselecting", function (e) {
                $(this).data('state', 'unselected');
            });
        });

        // decimal2
        $('.decimal-2').inputmask('decimal', {
            rightAlign: false,
            digits: 2
        });

        // decimal2
        $('.decimal-r-2').inputmask('decimal', {
            rightAlign: true,
            digits: 2
        });

        // decimal4
        $('.decimal-4').inputmask('decimal', {
            rightAlign: false,
            digits: 4
        });

        // decimal4
        $('.decimal-r-4').inputmask('decimal', {
            rightAlign: true,
            digits: 4
        });

        // dualbox
        $.each($('select[multiple="multiple"][data-dualbox]'), function (index, item) {
            var dualboxObj = $(item).bootstrapDualListbox({infoTextFiltered: '<span class="label label-purple label-lg">Filtrato</span>'});
            var dualboxContainer = dualboxObj.bootstrapDualListbox('getContainer');
            dualboxContainer.find('.btn').addClass('btn-white btn-info btn-bold');
        });

        /**
         * Scorre gli elementi, e per ciascuno trova il suo <button> che lo contiene. sposta gli attributi data-interaction al padre
         */
        $("[data-interaction=dropzoneizerParent]").each(function () {
            var btnParent = $(this).parents("button");
            btnParent.attr("data-url", $(this).attr("data-url"));
            btnParent.attr("data-parameters", $(this).attr("data-parameters"));
            btnParent.attr("data-callback", $(this).attr("data-callback"));
            btnParent.attr("data-init-callback", $(this).attr("data-init-callback"));
            btnParent.attr("data-sending-callback", $(this).attr("data-sending-callback"));
            btnParent.attr("data-error-callback", $(this).attr("data-error-callback"));
            btnParent.attr("data-interaction", "dropzone");
            // TODO: migliorare! FIX per chrome
            btnParent = $(this).parents("span");
            btnParent.attr("data-url", $(this).attr("data-url"));
            btnParent.attr("data-parameters", $(this).attr("data-parameters"));
            btnParent.attr("data-callback", $(this).attr("data-callback"));
            btnParent.attr("data-init-callback", $(this).attr("data-init-callback"));
            btnParent.attr("data-sending-callback", $(this).attr("data-sending-callback"));
            btnParent.attr("data-error-callback", $(this).attr("data-error-callback"));
            btnParent.attr("data-interaction", "dropzone");
        });
        $("[data-interaction=dropzone]").each(function () {
            var url = $(this).attr("data-url");
            var parameters = $(this).attr("data-parameters") || "";
            var callback = $(this).attr("data-callback") || null;
            var initCallback = $(this).attr("data-init-callback") || null;

            /*
            initCallback = function () {
                var that = this;
                that.on('sending', function(file, xhr, data) {
                    if ($.trim($("#month").val()) == "" || $.trim($("#year").val()) == "") {
                        that.removeFile(file);
						app.error("", "Mese e anno obbligatori");
                    } else {
                        data.append("month", $("#month").val());
                        data.append("year", $("#year").val());
					}
                    app.block(1);
                });
			};
			
            */

            var sendingCallback = $(this).attr("data-sending-callback") || null;
            var errorCallback = $(this).attr("data-error-callback") || null;
            var acceptedFiles = $(this).attr("data-accepted-files") || undefined;

            if (!$(this).attr('dropzoned')) {
                $(this).attr('dropzoned', 'true');

                $(this).dropzone({
                    timeout: 180000,
                    url: url + parameters,
                    headers: $.ajaxSetup().headers,
                    previewTemplate: '<div style="display: none;"></div>',
                    acceptedFiles: acceptedFiles,
                    init: initCallback ? eval(initCallback) : function () {
                        if (typeof window[initCallback] == 'function') initCallback();
                    },
                    sending: function (file, data, formData) {
                        if (sendingCallback) {
                            var fnz = app.eval(sendingCallback);
                            if (typeof fnz == 'function') {
                                fnz(file, data, formData);
                            }
                        }
                    },
                    success: function (file, data) {
                        app.block(0);
                        $('.dz-preview').detach();

                        if (callback) {
                            var functionCallBack = eval(callback);
                            if (typeof functionCallBack == 'function') {
                                functionCallBack($(this), data);
                            } else {
                                console.warn("Callback non trovata: " + callback);
                            }
                        }

                    },
                    error: function (file, data) {
                        if (typeof window[errorCallback] == 'function') {
                            window[errorCallback](data);
                        } else {
                            app.block(0);
                            app.error("Errore in fase di caricamento");
                        }
                    }
                });
            }
        });

        var initCharts = function () {
            var charts = $('.percentage');

            charts.each(function () {
                var $box = $(this).closest('.infobox');
                var barColor = $(this).data('color') || (!$box.hasClass('infobox-dark') ? $box.css('color') : 'rgba(255,255,255,0.95)');
                var trackColor = barColor == 'rgba(255,255,255,0.95)' ? 'rgba(255,255,255,0.25)' : '#E2E2E2';
                var size = parseInt($(this).data('size')) || 50;

                if (typeof $(this).easyPieChart !== 'undefined') {
                    $(this).easyPieChart({
                        barColor: barColor,
                        trackColor: trackColor,
                        scaleColor: false,
                        lineCap: 'butt',
                        lineWidth: parseInt(size / 10),
                        animate: ace.vars['old_ie'] ? false : 1000,
                        size: size,
                        onStop: function (old_value, new_value) {
                            $(this.el).find('span').text(~~new_value);
                        }
                    });
                }
            });


            /*charts.easyPieChart({
             animate: 500,

             });*/
            $('.updatePieCharts').on('click', function (e) {
                e.preventDefault();
                charts.each(function () {
                    $(this).data('easyPieChart').update(Math.floor(100 * Math.random()));
                });
            });
        };

        initCharts();

        app.initAutocomplete();

        app.initCookiesIf();
    },

    initCookiesIf: function () {
        if (typeof Cookies == 'undefined') {
            return;
        }
        $("[data-cookie-if]").each(function (index, obj) {

            var cookie_name = $(obj).attr("data-cookie-if");
            var cond = Cookies.get(cookie_name.replace("!", "")) != undefined;
            if (cookie_name.indexOf("!") == 0) {
                cond = !cond;
            }

            if (cond) {
                $(obj).show();
            } else {
                $(obj).hide();
            }
        });
    },

    eventCookieIf: function (obj, status) {
        var cookie_name = $(obj).attr("data-cookie-if");
        if (typeof cookie_name == 'undefined') {
            cookie_name = $(obj).parents("[data-cookie-if]").attr("data-cookie-if");
        }
        cookie_name = cookie_name.replace("!", "");
        if (status) {
            Cookies.set(cookie_name, 1);
        } else {
            Cookies.remove(cookie_name);
        }
        app.initCookiesIf();
    },

    getUrlParams: function (url) {
        var ret = '';

        if (url.indexOf("?") >= 0)
            ret = url.substring(url.indexOf("?"));

        return ret;
    },

    runToggleEditDiv: function () {
        $("[data-interaction=toggleEditDiv]").each(function () {
            var idDiv = $(this).attr("data-ref");
            if ($(this).is(":checked")) {
                $("#" + idDiv).find('input, select').prop('disabled', false);
            } else {
                $("#" + idDiv).find('input, select').prop('disabled', true);
            }
            $("#" + idDiv).find('select.chosen').trigger("chosen:updated");

            $(this).prop('disabled', false);
            $(this).removeAttr('disabled');
        });
    },

    locationHref: function (url, withBlank, onlyBackend, replace) {
        withBlank = withBlank || 0;
        replace = replace || 0;
        if (typeof onlyBackend == 'undefined')
            onlyBackend = 1;

        if (url) {
            if (onlyBackend) {
                url = lib.getAceLink(url);
            } else {
                var base = app.siteUrl;

                if (url.indexOf(base) < 0) {
                    if (url[0] == "/")
                        url = url.substring(1, url.length);

                    url = base + "/" + url;
                }
            }
        }

        if (withBlank == 1) {
            window.open(
                url,
                '_blank'
            );
            //app.blockUI(false);
        } else {
            if (document.URL == url) {
                app.reload();
            } else {
                if (replace)
                    window.location.replace(url);
                else
                    window.location.href = url;
            }
        }

    },

    href: function (url, onlyBackend, replace) {
        app.locationHref(url, 0, onlyBackend, replace);
    },

    hrefJson: function (data) {
        if (data.response) window.location.href = data.message; else app.error(data.message);
    },

    hrefDropzone: function (file, data) {
        app.hrefJson(data);
    },

    block: function (blk, msg) {
        if (blk) {
            // BlockUI
            msg = msg || typeof T.blockUIMsg !== 'undefined' ? T.blockUIMsg : 'CARICAMENTO IN CORSO...'

            $.blockUI({
                message: msg,
                baseZ: 2000,
                css: {
                    border: 'none',
                    padding: '15px',
                    backgroundColor: '#000',
                    'border-radius': '10px',
                    opacity: .8,
                    color: '#fff'
                }
            });
        } else {
            $.unblockUI();
        }
    },

    location: function (location) {
        app.block(1);
        window.location.href = location;
        app.block(0);
    },

    reload: function () {
        // AJAX
        $('#page-content-section').ace_ajax("reload");

        app.blockUI(false);
    },

    setLoading: function (active) {

    },

    /**
     * Mostra un confirm
     */
    requestConfirm: function (title, message) {

        var passVerificata = false;

        if (confirm(message)) {

            var password = prompt("Inserire la password di conferma");

            if ($.trim(password) != "") {
                $.ajax({
                    type: "POST",
                    url: "?p=server&a=checkPassword",
                    async: false,
                    data: "password=" + password,
                    success: function (data) {

                        if (data.response) {
                            passVerificata = true;
                        } else {
                            app.error(data.message);
                        }

                    }

                });
            }
        }

        return passVerificata;
    },

    /**
     * Mostra un GRITTER messaggio di warning
     */
    warning: function (title, message) {
        $.gritter.add({
            title: typeof message == 'undefined' ? "" : title,
            text: typeof message == 'undefined' ? title : message,
            class_name: 'gritter-warning'
        });
    },

    /**
     * Mostra un GRITTER messaggio di error
     */
    error: function (title, message) {
        $.gritter.add({
            title: typeof message == 'undefined' ? "" : title,
            text: typeof message == 'undefined' ? title : message,
            class_name: 'gritter-error',
        });
    },

    goBack: function () {
        history.back();
    },

    /**
     * Mostra un GRITTER messaggio di successo
     */
    success: function (title, message) {
        $.gritter.add({
            // (string | mandatory) the heading of the notification
            title: typeof message == 'undefined' ? "" : title,
            // (string | mandatory) the text inside the notification
            text: typeof message == 'undefined' ? title : message,
            class_name: 'gritter-success'
        });
    },

    // Callback da utilizzare quando non possibile passare parametri
    blockUI_true: function () {
        app.blockUI(true);
    },
    // Callback da utilizzare quando non possibile passare parametri
    blockUI_false: function () {
        app.blockUI(false);
    },
    dropzoneDefaultCallback: function (obj, data) {
        if (data.response)
            app.success(data.message);
        else
            app.error(data.message);
    },
    dropzoneDefaultCallback_reload: function (obj, data) {
        app.dropzoneDefaultCallback(obj, data);
        app.reload();
    },

    // Imposta un messaggio all'interno del blockUI, e' possibile appendere il messaggio a quello esistente
    blockUI_setMessage: function (msg, appendMessage) {
        appendMessage = appendMessage || false;
        if (appendMessage) {
            var curVal = '';
            if (!$(".blockUI.blockMsg.blockPage").data('oroginal_message')) {
                curVal = $(".blockUI.blockMsg.blockPage").html();
                $(".blockUI.blockMsg.blockPage").data('oroginal_message', curVal);
            } else {
                curVal = $(".blockUI.blockMsg.blockPage").data('oroginal_message');
            }

            $(".blockUI.blockMsg.blockPage").html(curVal + " " + msg);
        } else {
            $(".blockUI.blockMsg.blockPage").html(msg);
        }
    },

    blockUI: function (blk, message) {
        if (app.dontBlock)
            return;

        if (blk) {
            message = message || typeof T.blockUIMsg !== 'undefined' ? T.blockUIMsg : 'CARICAMENTO IN CORSO...'

            // BlockUI
            $.blockUI({
                message: message,
                baseZ: 99999,
                css: {
                    border: 'none',
                    padding: '15px',
                    backgroundColor: '#000',
                    'border-radius': '10px',
                    opacity: .8,
                    color: '#fff'
                }
            });
        } else {
            $.unblockUI();
        }
    },
    dontBlock: false,

    autocomplizer: function (objSelect, idNewObject, funcSelect, funcNothingSelected, funcBeginWithSelected, myClass) {

        var hasOptionSelected = objSelect.find('option:selected').length;
        if (hasOptionSelected != 0) {
            var selectedText = objSelect.find('option:selected').text();
            var selectedValue = objSelect.find('option:selected').val();
        }

        if (!myClass)
            myClass = objSelect.attr("class");

        var autoc = $("<input type='text' class='" + myClass + "' id='" + idNewObject + "'>");
        var aVal = [];
        objSelect.find('option').each(function (index, obj) {
            aVal.push({
                id: $(obj).val(),
                value: $(obj).text()
            });
        })

        objSelect.replaceWith(autoc);

        var hidden_original = $("<input type='hidden' id='" + objSelect.attr('id') + "' name='" + objSelect.attr('name') + "'>");
        autoc.parent().append(hidden_original);

        if (!funcSelect) {
            funcSelect = function (id, value) {
                $("#" + idNewObject).val(value);
                hidden_original.val(id);
            };
        }
        if (!funcNothingSelected) {
            funcNothingSelected = function () {
                hidden_original.val(0);
            };
        }
        if (!funcBeginWithSelected) {
            funcBeginWithSelected = function (text, value) {
                $("#" + idNewObject).val(text);
                hidden_original.val(value);
            };
        }

        if (hasOptionSelected != 0) {
            funcBeginWithSelected(selectedText, selectedValue);
        }

        $('#' + idNewObject).autocomplete({
            source: aVal,
            focus: function () {
                $(this).trigger('keydown.autocomplete');
            },
            select: function (event, ui) {
                funcSelect(ui.item.id, ui.item.value);
                event.preventDefault();
                return false;
            },
            change: function (event, ui) {
                if (ui.item == null)
                    funcNothingSelected();
            },
        });
    },
    setLanguage: function (shortCode) {
        $.cookie('app_language', shortCode);
    },
    screenshotPreview: function () {

        xOffset = 10;
        yOffset = 30;

        $("a.imagePreview").hover(function (e) {
                this.t = this.title;
                this.title = "";
                var c = (this.t != "") ? "<br/>" + this.t : "";
                $("body").append("<p id='imagePreview'><img src='" + this.rel + "' alt='Preview' />" + c + "</p>");
                $("#imagePreview")
                    .css("top", (e.pageY - xOffset) + "px")
                    .css("left", (e.pageX + yOffset) + "px")
                    .fadeIn("fast");
            },
            function () {
                this.title = this.t;
                $("#imagePreview").remove();
            });
        $("a.imagePreview").mousemove(function (e) {
            $("#imagePreview")
                .css("top", (e.pageY - xOffset) + "px")
                .css("left", (e.pageX + yOffset) + "px");
        });
    },

    eval: function (string) {
        var scope = window;
        var scopeSplit = string.split('.');
        for (var i = 0; i < scopeSplit.length - 1; i++) {
            scope = scope[scopeSplit[i]];

            if (scope == undefined) return;
        }

        return scope[scopeSplit[scopeSplit.length - 1]];
    },

    platform: {
        detection: function () {
            if (navigator.platform.indexOf("iPad", 0) >= 0)
                return ("ios");
            else if (navigator.platform.indexOf("iPhone", 0) >= 0)
                return ("ios");
            else if (navigator.userAgent.indexOf("Android", 0) >= 0)
                return ("android");
            else
                return "desktop";
        },
        deviceType: function () {
            if (navigator.platform.indexOf("iPad", 0) >= 0)
                return ("tablet");
            else if (navigator.platform.indexOf("iPhone", 0) >= 0)
                return ("phone");
            else if (navigator.userAgent.indexOf("Android", 0) >= 0) {
                if (navigator.userAgent.indexOf("Mobile", 0) >= 0)
                    return ("phone");
                else
                    return ("tablet");
            }
            else
                return "tablet";
        },
        isMobile: function () {
            return app.platform.detection() != "desktop";
        }
    }
};

/**
 * Estensione jquery per metodi put e delete ajax
 */
jQuery.each(["put", "delete", "post_sync", "post_json"], function (i, method) {
    jQuery[method] = function (url, data, callback, type) {
        if (jQuery.isFunction(data)) {
            type = type || callback;
            callback = data;
            data = undefined;
        }

        var async = true;

        if (method == 'post_sync') {
            method = 'post';
            async = false;
        }

        var headers = {};
        if (method == 'post_json') {
            method = 'post';
            headers = {
                'Accept': 'application/json; charset=utf-8'
            };
        }

        return jQuery.ajax({
            url: url,
            headers: headers,
            type: method,
            dataType: type,
            data: data,
            success: callback,
            async: async
        }).error(callback);
    };
});

/**
 * Imposta un array di preferenze all'interno del local storage
 * @type {{get: preference.get, set: preference.set}}
 */
var preference = {

    get: function (name) {
        return Cookies.get("preference['" + name + "']");
    },

    set: function (name, value) {
        return Cookies.set("preference['" + name + "']", value);
    }

};

var lib = {
    dataPrintTrue: "þ",
    dataPrintFalse: "¨",
    /**
     * Converte un array di parameteri in formato p1=1&p2=2... in un oggetto json
     */
    convertGetParamInJSON: function (stringParameters) {
        var hash;
        var ret = {};
        var hashes = stringParameters.split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            if (typeof hash[0] != "undefined") {
                ret[hash[0]] = (typeof hash[1] != "undefined") ? hash[1] : "";
            }

        }
        return ret;
    },

    /**
     * Restituisce il numero di secondi un un time
     * @param time
     * @returns {number}
     */
    timeToSeconds: function (time) {
        var parts = time.split(':');
        return (+parts[0]) * 60 * 60 + (+parts[1]) * 60 + (+parts[2]);
    },


    /**
     * Converte un numero di secondi in formato H:i:s
     * @param sec
     */
    timeToHHMMSS: function (sec, format) {
        format = format || 'HHMMSS';

        var sec_num = parseInt(sec, 10); // don't forget the second parm
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        switch (format) {
            case 'HHMMSS':
                return hours + ':' + minutes + ':' + seconds;
            case 'HHMM':
                return hours + ':' + minutes;
        }
        return "FORMAT NOT DEFINED";
    },

    /**
     * Restituisce una stringa rappresentante la somma di due time
     * @param time1
     * @param time2
     * @param toString
     * @returns {*}
     */
    timeSum: function (time1, time2, toString) {
        toString = toString || false;
        var sec = lib.timeToSeconds(time1) + lib.timeToSeconds(time2);
        if (toString)
            return lib.timeToHHMMSS(sec);
        else
            return sec;
    },

    /**
     * Restituisce una stringa rappresentante la differenza di due time ( time1 - time2 )
     * @param time1
     * @param time2
     * @param toString
     * @returns {*}
     */
    timeSub: function (time1, time2, toString) {
        toString = toString || false;
        var sec = lib.timeToSeconds(time1) - lib.timeToSeconds(time2);
        if (toString)
            return lib.timeToHHMMSS(sec);
        else
            return sec;
    },

    /**
     * Ritorna l'oggetto figlio all'interno di un oggetto che ha il 'key = val'
     */
    getObjects: function (obj, key, val, level, currentlevel, compareFunction) {
        var objects = [];

        level = level || 9999;
        currentlevel = currentlevel || 0;
        compareFunction = compareFunction || function (a, b) {
            return a == b;
        };

        if (currentlevel >= level)
            return objects;

        for (var i in obj) {
            if (!obj.hasOwnProperty(i)) continue;
            if (typeof obj[i] == 'object') {
                objects = objects.concat(lib.getObjects(obj[i], key, val, level, currentlevel + 1, compareFunction));
            } else if (i == key && compareFunction(obj[key], val)) {
                objects.push(obj);
            }
        }
        return objects;
    },

    /**
     * Elimina da un array di oggetti quello che ha key=val
     * @param arrayOfObjects
     * @param key
     * @param val
     */
    removeObjects: function (arrayOfObjects, key, val) {
        for (var i = 0; i < arrayOfObjects.length; i++) {
            var obj = arrayOfObjects[i];

            if (obj[key] == val) {
                arrayOfObjects.splice(i, 1);
                i--;
            }
        }
    },

    /**
     * Da utilizzare sul form - submit
     */
    formSerialize: function (form, print, skipNotChecked) {

        var o = {};
        var a = form.serializeArray();
        // fix x aggiungere anche i check deselezionati
        if (!skipNotChecked) {
            a = a.concat(
                form.find('input[type=checkbox]:not(:checked)').map(
                    function () {
                        return {"name": this.name, "value": 0}
                    }).get()
            );
        }

        $.each(a, function () {

            var value = this.value;

            var procedi = true;

            if (form.find('[name="' + this.name + '"]').attr('type') == 'checkbox') {
                if (!skipNotChecked) {
                    if (this.value == 'on' || parseInt(this.value) == 1) {
                        value = 1;
                    } else {
                        value = 0;
                    }

                    if (typeof form.find('[name="' + this.name + '"]').attr('data-returnvalue') != "undefined") {
                        if (value == 0)
                            procedi = false;

                        value = form.find('[name="' + this.name + '"]').attr('data-returnvalue');
                    }

                    if (print) {
                        value = form.find('[name="' + this.name + '"]').is(":checked") ? lib.dataPrintTrue : lib.dataPrintFalse;
                    }
                }
            }

            if (procedi) {
                if (o[this.name] !== undefined) {
                    if (!o[this.name].push) {
                        o[this.name] = [o[this.name]];
                    }
                    o[this.name].push((value == undefined) ? "" : value);
                } else {
                    o[this.name] = (value == undefined) ? "" : value;
                }
            }
        });

        return o;
    },

    queryStringSerialize: function (obj) {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    },

    /**
     * Copia gli attributi di un elemento dentro un altro
     */
    copyAttributesToOtherElement: function (elemOrig, elemDest) {
        var attributes = elemOrig.prop("attributes");

        // loop through <select> attributes and apply them on <div>
        $.each(attributes, function () {
            elemDest.attr(this.name, this.value);
        });
    },

    today: function () {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return mm + '/' + dd + '/' + yyyy;
    },

    getAceLink: function (link) {
        var baseUrl = window.location.href.substr(0, window.location.href.indexOf("#"));

        var page = link.replace(baseUrl, "").replace("#", "");
        if (page.length && page[0] == "/")
            page = page.substr(1);

        return baseUrl + "#" + page;
    }

};

var loader = {
    download: function (show, text) {
        show = show || 0;
        var modalId = "#modalLoader";
        if (show) {
            text = text || "Generazione del documento in corso...";
            text = "<h3 style='text-align:center'><i class='ace-icon fa fa-spinner fa-spin orange bigger-125' ></i> " + text + "</h3>";
            $(modalId + ' h4.modal-title').html('Attendere...');
            $(modalId + ' .modal-body').html(text);
            $(modalId + ' .btn-primary').hide();
            $(modalId + ' .btn-default').text('Chiudi');

            $(modalId + ' .btn-default').unbind("click").bind('click', function () {
                $(modalId + ' .close').trigger('click');
            });

            $(modalId).modal('show');
        } else {
            $(modalId + ' .close').trigger('click');
        }
    }
};

jQuery.fn.extend({
    /**
     * Bind del setInterval ad un oggetto nel dom, quando l'oggetto viene rimosso, l'interval viene stoppato
     * @param callback
     * @param timeout
     */
    setInterval: function (callback, timeout) {
        var int = setInterval(callback, timeout);
        this.on("remove", function () {
            clearInterval(int);
        })
    }
});

// funzione replaceAll
String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

// Selettore $("td[data-interaction=tostring_operatore]:contains( ... )    case insensitive
jQuery.expr[":"].containsI = jQuery.expr.createPseudo(function (arg) {
    return function (elem) {
        return jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
});